<template>
    <div class="Options" :class="{ ['hide-shadow']: hideShadow }">
        <router-link class="option" v-for="(option, index) in options" :key="index" :class="{ selected: index === selected }" :to="option.link">
            <Option :icon="option.icon" :theme="index === selected ? 'gold' : 'grey'" />
            <h4>{{ option.name }}</h4>
        </router-link>
    </div>
</template>

<script>

    // Components.
    import Option from '@/components/vectors/Option.vue';

    export default {
        name: 'Options',
        components: {
            Option,
        },
        props: {
            options: Array,
            selected: Number,
            hideShadow: Boolean,
        },
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Options {

        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 25;

        width: 100%;

        display: flex;

        background-color: color(White);
        border-top: 1px solid color(WildSand);
        box-shadow: 0 0 15px color(Black, 0.1);

    }

    .Options.hide-shadow {
        box-shadow: none;
    }

    .option {

        position: relative;

        width: 20%;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: color(OldGold);

    }

    .option:before,
    .option:after {

        content: '';

        position: absolute;
        top: 0;
        z-index: -1;

        height: 100%;

        background-color: color(Emperor);
        opacity: 0;

    }

    .option:before {

        left: calc(20.7107px / 2);
        transform: skewX(-22.5deg);

        width: calc(100% - (20.7107px));

    }

    .option:after {
        width: 50%;
    }

    .option:first-of-type:after {
        left: 0;
    }

    .option:last-of-type:after {
        right: 0;
    }

    .option.selected {
        width: 40%;
    }

    .option.selected:before,
    .option.selected:first-of-type:after,
    .option.selected:last-of-type:after {
        opacity: 1;
    }

    .option:not(.selected) {
        opacity: 0.25;
    }

    .option h4 {
        text-transform: uppercase;
        margin-left: size(Small);
    }

    .option:first-of-type.selected h4 {
        margin-right: calc(20.7107px / 2);
    }

    .option:last-of-type.selected h4 {
        margin-right: calc(-20.7107px / 2);
    }

    .option:not(.selected) h4 {
        display: none;
    }

</style>

<style lang="scss">

    .Options a svg {
        width: 24px;
        height: 24px;
    }

</style>
